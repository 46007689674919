window.addEventListener('load', () => {
  if (!document.querySelector('.select')) {
    return false
  }

  const selects = document.querySelectorAll('.select')
  const selectsTitle = document.querySelectorAll('.select_title')

  function initSelect (select) {
    const options = select.querySelectorAll('.select__item')
    const hiddenSelect = select.querySelector('select')
    const hiddenOptions = [...hiddenSelect.querySelectorAll('option')]
    const optionsBlock = select.querySelector('.select__options')
    const input = select.querySelector('.select__input')
    const arrow = select.querySelector('.select__arrow')
    const label = select.querySelector('.form__bg')

    label.classList.remove('form__bg_active')

    const toggleOptions = () => {
      (!optionsBlock.hasAttribute('style') || !optionsBlock.getAttribute('style')) ? optionsBlock.style.maxHeight = `${optionsBlock.scrollHeight}px` : optionsBlock.removeAttribute('style')
      arrow.classList.toggle('select__arrow_active')
      if (input.children.length === 0 && label.classList.contains('form__bg_active')) {
        label.classList.remove('form__bg_active')
      } else {
        label.classList.add('form__bg_active')
      }
    }

    const selectOption = (el) => {
      const id = el.dataset.id
      const currentOption = hiddenOptions.find(el => el.dataset.id === id)
      input.insertAdjacentElement('afterbegin', el.cloneNode(true))
      hiddenOptions.forEach(el => el.removeAttribute('selected'))
      hiddenSelect.value = currentOption.value
      hiddenSelect.dispatchEvent(new Event('change'))
    }

    const chooseValue = (event) => {
      const item = event.currentTarget
      selectOption(item)
      if (input.children[1]) {
        input.children[1].remove()
      }
      toggleOptions()
    }

    options.forEach(el => el.addEventListener('click', chooseValue))
    input.addEventListener('click', toggleOptions)
  }

  function initSelectTitle (select) {
    const options = document.querySelectorAll('.select_title .select__item')
    const hiddenSelect = select.querySelector('select')

    const hiddenOptions = [...select.querySelectorAll('option')]
    const input = select.querySelector('.select__input')

    const selectOption = (el) => {
      const id = el.dataset.id
      const currentOption = hiddenOptions.find(el => el.dataset.id === id)
      input.insertAdjacentElement('afterbegin', el.cloneNode(true))
      hiddenOptions.forEach(el => el.removeAttribute('selected'))
      hiddenSelect.value = currentOption.value
    }
    selectOption(options[0])
  }

  const closeDrops = (event) => {
    if (!event.target.closest('.select')) {
      document.querySelectorAll('.select__options').forEach(el => el.removeAttribute('style'))
      document.querySelectorAll('.select .form__bg').forEach(el => {
        if (el.nextElementSibling.querySelector('.select__input').children.length === 0) {
          el.classList.remove('form__bg_active')
        }
      })
      document.querySelectorAll('.select__arrow').forEach(el => el.classList.remove('select__arrow_active'))
    }
  }

  selects.forEach(el => initSelect(el))
  selectsTitle.forEach(el => initSelectTitle(el))
  window.addEventListener('click', (event) => {
    closeDrops(event)
  })
  document.querySelector('.modal').addEventListener('click', (event) => {
    closeDrops(event)
  })
})
