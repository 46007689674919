window.addEventListener('load', () => {
  if (!document.querySelector('.advantages')) {
    return false
  }

  const btnRow = document.querySelectorAll('.advantages__section-content-arrow')

  btnRow.forEach(btn => {
    btn.addEventListener('click', () => {
      btn.closest('.advantages__section-content').classList.toggle('advantages__section-content_active')
    })
  })
})
