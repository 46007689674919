window.addEventListener('load', () => {
  const AOS = require('aos')

  const main = document.querySelector('.main')

  const eventId = 0
  const hrId = 1
  const educationId = 2
  const btlId = 3
  const chatId = 4

  const btnBot = document.querySelectorAll('.bot__chat-content-message .button')
  const advantages = document.querySelector('.advantages')

  const botHr = btnBot[hrId]
  const botEducation = btnBot[educationId]
  const botBtl = btnBot[btlId]
  const botChat = btnBot[chatId]
  const botEvent = btnBot[eventId]

  const selects = document.querySelectorAll('.select_title')
  const hiddenOptions = document.querySelectorAll('.select_title .select__hidden')
  const inputs = document.querySelectorAll('.select_title .select__input')

  function changeSelect(id) {
    inputs.forEach(el => {
      el.insertAdjacentElement('afterbegin', el.parentElement.querySelector(`[data-id="${id}"]`).cloneNode(true))
      if (el.children.length > 1) {
        el.children[el.children.length - 1].remove()
      }
    })
    hiddenOptions.forEach(options => {
      [...options.children].forEach(el => el.removeAttribute('selected'))
      options.querySelector(`[data-id="${id}"]`).setAttribute('selected', 'selected')
    })
  }

  //event
  function btnEvent() {
    botEvent.classList.add('button_active')
    botHr.classList.remove('button_active')
    botEducation.classList.remove('button_active')
    botBtl.classList.remove('button_active')
    botChat.classList.remove('button_active')

    window.scrollBy({
      top: advantages.getBoundingClientRect().top - 150,
      behavior: 'smooth'
    })
  }

  function eventActive() {
    main.classList.add('main_event')
    main.classList.remove('main_hr')
    main.classList.remove('main_education')
    main.classList.remove('main_btl')
    main.classList.remove('main_chatbot')

    window.location.hash = "#event"
  }

  // hr
  function btnHr() {
    botEvent.classList.remove('button_active')
    botHr.classList.add('button_active')
    botEducation.classList.remove('button_active')
    botBtl.classList.remove('button_active')
    botChat.classList.remove('button_active')

    window.scrollBy({
      top: advantages.getBoundingClientRect().top - 150,
      behavior: 'smooth'
    })
    AOS.refresh()
  }

  function hrActive() {
    main.classList.remove('main_event')
    main.classList.add('main_hr')
    main.classList.remove('main_education')
    main.classList.remove('main_btl')
    main.classList.remove('main_chatbot')

    window.location.hash = "#hr"
    btnHr()
  }

  // onboarding
  function btnEducation() {
    botEvent.classList.remove('button_active')
    botHr.classList.remove('button_active')
    botEducation.classList.add('button_active')
    botBtl.classList.remove('button_active')
    botChat.classList.remove('button_active')

    window.scrollBy({
      top: advantages.getBoundingClientRect().top - 150,
      behavior: 'smooth'
    })
  }

  function educationActive() {
    main.classList.remove('main_event')
    main.classList.remove('main_hr')
    main.classList.add('main_education')
    main.classList.remove('main_btl')
    main.classList.remove('main_chatbot')

    window.location.hash = "#education"
    btnEducation()
  }

  //BTL
  function btnBtl() {
    botEvent.classList.remove('button_active')
    botHr.classList.remove('button_active')
    botEducation.classList.remove('button_active')
    botBtl.classList.add('button_active')
    botChat.classList.remove('button_active')

    window.scrollBy({
      top: advantages.getBoundingClientRect().top - 150,
      behavior: 'smooth'
    })
  }

  function btlActive() {
    main.classList.remove('main_event')
    main.classList.remove('main_hr')
    main.classList.remove('main_education')
    main.classList.add('main_btl')
    main.classList.remove('main_chatbot')

    window.location.hash = "#btl"
    btnBtl()
  }

  // chatbot
  function btnChat() {
    botEvent.classList.remove('button_active')
    botHr.classList.remove('button_active')
    botEducation.classList.remove('button_active')
    botBtl.classList.remove('button_active')
    botChat.classList.add('button_active')

    window.scrollBy({
      top: advantages.getBoundingClientRect().top - 150,
      behavior: 'smooth'
    })
    AOS.refresh()
  }

  function chatActive() {
    main.classList.remove('main_event')
    main.classList.remove('main_hr')
    main.classList.remove('main_education')
    main.classList.remove('main_btl')
    main.classList.add('main_chatbot')

    window.location.hash = "#chatbot"
    btnChat()
  }

  //dropdown
  function activeSelect(select) {
    const options = select.querySelectorAll('.select__options .select__item')

    options.forEach(el => {
      el.addEventListener('click', () => {
        const optionsSelected = select.querySelectorAll('.select__input .select__item')
        const idSelected = optionsSelected[0].dataset.id

        if (idSelected == '0') {
          eventActive()
          btnEvent()
          changeSelect(eventId)
        } else if (idSelected === '1') {
          hrActive()
          changeSelect(hrId)
        } else if (idSelected === '2') {
          educationActive()
          changeSelect(educationId)
        } else if (idSelected === '3') {
          btlActive()
          changeSelect(btlId)
        } else {
          chatActive()
          changeSelect(chatId)
        }
      })
    })

    // sroll on content after click in chat block
    botEvent.addEventListener('click', () => {
      eventActive()
      btnEvent()
      changeSelect(eventId)
    })

    botHr.addEventListener('click', () => {
      hrActive()
      changeSelect(hrId)
    })

    botEducation.addEventListener('click', () => {
      educationActive()
      changeSelect(educationId)
    })

    botBtl.addEventListener('click', () => {
      btlActive()
      changeSelect(btlId)
    })

    botChat.addEventListener('click', () => {
      chatActive()
      changeSelect(chatId)
    })
  }

  // dowload content
  if (window.location.hash === "#event")  {
    eventActive()
    btnEvent()
    changeSelect(eventId)
  } else if (window.location.hash === "#hr") {
    hrActive()
    changeSelect(hrId)
  } else if (window.location.hash === "#education") {
    educationActive()
    changeSelect(educationId)
  } else if (window.location.hash === "#btl") {
    btlActive()
    changeSelect(btlId)
  } else if (window.location.hash === "#chatbot") {
    chatActive()
    changeSelect(chatId)
  } else {
    eventActive()
    changeSelect(eventId)
  }

  selects.forEach(el => activeSelect(el))
})
