import Swiper, {Pagination, Navigation, Mousewheel, Keyboard} from 'swiper'

Swiper.use([Pagination, Navigation, Mousewheel, Keyboard])

window.addEventListener('load', () => {
  if (!document.querySelector('.steps .swiper-container')) {
    return false
  }

  const swiper = new Swiper(".steps .swiper-container", {
    cssMode: true,
    spaceBetween: 100,
    resizeObserver: true,
    observer: true,
    preloadImages: true,
    lazy: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: '.steps__process',
      type: 'bullets',
      clickable: true
    },
    mousewheel: true,
    keyboard: true,
  })
})
