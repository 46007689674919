window.addEventListener('load', () => {
  const container = document.querySelector('.statistics__numbers')
  const number = document.querySelectorAll('.statistics__numbers-container b')

  const listener = () => {
    number.forEach(item => {
      let start = +item.innerHTML
      let end = +item.dataset.max

      const { top } = container.getBoundingClientRect()

      if (top < window.innerHeight) {
        let interval = setInterval(() => {
          item.innerHTML = ++start
          if (start === end) {
            clearInterval(interval)
          }
        }, 80)

        window.removeEventListener('scroll', listener)
      }
    })
  }

  window.addEventListener('scroll', listener)
  listener()
})

