import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

window.addEventListener('load', () => {
  if (!document.querySelector('.header')) {
    return false
  }

  const menuBtn = document.querySelector('.header__menu-btn')
  const hiddenMenu = document.querySelector('.hidden-menu')
  const closeMenu = document.querySelector('.hidden-menu__close')
  const menu = document.querySelector('.hidden-menu .menu-links')

  const toggleMenu = () => {
    hiddenMenu.classList.toggle('hidden-menu_active')

    if (document.querySelector('.hidden-menu_active')) {
      disableBodyScroll(hiddenMenu)
    } else {
      enableBodyScroll(hiddenMenu)
    }
  }

  menuBtn.addEventListener('click', toggleMenu)
  closeMenu.addEventListener('click', toggleMenu)

  menu.addEventListener('click', () => {
    enableBodyScroll(hiddenMenu)

    setTimeout(() => {
      hiddenMenu.classList.remove('hidden-menu_active')
    }, 400)
  })
})
