window.addEventListener('load', () => {
  if (!document.querySelector('.header')) {
    return false
  }

  const header = document.querySelector('.header')
  const headerHeight = 60

  const listener = () => {
    const scrolled = window.pageYOffset

    if (scrolled > headerHeight && !header.classList.contains('out')) {
      header.classList.add('scroll')
    } else {
      header.classList.remove('scroll')
    }
  }

  window.addEventListener('scroll', listener)
  listener()
})
